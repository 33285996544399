import React from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import './custom-bar.css'
import logo from './logo.png'

export const NavebarPublic = () => {


    return (
        <>
            <Navbar className="nav-bar-custom">
            <Navbar.Brand > <img src={logo} alt="ANACEM" width="200px"></img></Navbar.Brand>
                {/* <Nav className="mr-auto">
                    <Nav.Link className="navbar-link" href='/'>Encuentra tus Certificados</Nav.Link>
                </Nav> */}
                <Navbar.Collapse className="justify-content-end">
                    <Nav.Link className="navbar-link" href='/auth/login'> Acceso Miembros Autorizados </Nav.Link>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

