import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { Cargarcsv } from '../certificados/Cargarcsv';
import { DefaultCertificados } from '../certificados/defaultCertificados';
import { Navebar } from '../ui/Navebar';
import CustomCertificados from '../CustomCertificados/customCertificados';


export const privateMainPage = () => {

    return (
        <>
            <Navebar />
            <Tabs defaultActiveKey='tab1' id="tabs-test">
                <Tab eventKey='tab1' title='Usar plantilla por defecto' >
                <DefaultCertificados />
                </Tab >

                {/* <Tab eventKey='tab2' title='Crear nuevo certificado'  >
                <Cargarcsv />
                </Tab> */}


            </Tabs>
        </>
    )

}



