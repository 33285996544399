import '../firebase/firebaseConfig'
import { getFirestore, query, where, collection, getDocs } from 'firebase/firestore';

export const getCertificadosByRut = async (rut) => {
  let results = [];
  const querydb = getFirestore();

  const queryCollection = query(collection(querydb, "certificados"), where("rut", "==", rut));

  const querySnapshot = await getDocs(queryCollection);

  querySnapshot.forEach((doc) => {
    const result = {
      id: doc.id,
      ...doc.data()
    }

    results.push(result);
  });

  return results;
}

export const getCertificadosByActividad = async (actividad) => {
  let results = [];
  const querydb = getFirestore();

  const queryCollection = query(collection(querydb, "certificados"), where("actividad", "==", actividad));
  const querySnapshot = await getDocs(queryCollection);

  querySnapshot.forEach((doc) => {
    results.push({...doc.data(), id: doc.id})
  });

  return results;
}

export const validateCertificadosByCode = async (code) => {
  let isValidate = false;
  let rut = null;
  const querydb = getFirestore();
  const queryCollection = query(collection(querydb, "certificados"), where("validar", "==", code));
  const querySnapshot = await getDocs(queryCollection);

  querySnapshot.forEach((doc) => {
    isValidate = true;
    rut = doc.data().rut;
  });

  return {
    result: isValidate,
    rut: rut
  };
}