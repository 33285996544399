import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

import anacem from '../../images/anacem.png';
import qr from '../../images/qr_anacem.png';

// Importar las imágenes de los certificados por defecto
//ACEM UA
import logo1UA from '../../images/ACEM UA/logo1.png';
import logo2UA from '../../images/ACEM UA/logo2.png';
import barraUA from '../../images/ACEM UA/barra.png';

//ACEM UAT
import logo1UAT from '../../images/ACEM UAT/logo1.png';
import logo2UAT from '../../images/ACEM UAT/logo2.png';
import barraUAT from '../../images/ACEM UAT/barra.png';

//ACEM UCSC
import logo1UCSC from '../../images/ACEM UCSC_/logo1.png';
import logo2UCSC from '../../images/ACEM UCSC_/logo2.png';
import barraUCSC from '../../images/ACEM UCSC_/barra.png';

//ACEM UFT
import logo1UFT from '../../images/ACEM UFT/logo1.png';
import logo2UFT from '../../images/ACEM UFT/logo2.png';
import barraUFT from '../../images/ACEM UFT/barra.png';

//ACEM UMAG
import logo1UMAG from '../../images/ACEM UMAG/logo1.png';
import logo2UMAG from '../../images/ACEM UMAG/logo2.png';
import barraUMAG from '../../images/ACEM UMAG/barra.jpg';

//ACEM UMT
import logo1UMT from '../../images/ACEM UMT/logo1.png';
import logo2UMT from '../../images/ACEM UMT/logo2.PNG';
import barraUMT from '../../images/ACEM UMT/barra.png';


//ACEM UNAB VIÑA
import logo1UNABVIÑA from '../../images/ACEM_UNAB_VINA/logo1.png';
import logo2UNABVIÑA from '../../images/ACEM_UNAB_VINA/logo2.png';
import barraUNABVIÑA from '../../images/ACEM_UNAB_VINA/barra.png';


//ACEM USACH
import logo1USACH from '../../images/ACEM USACH/logo1.png';
import logo2USACH from '../../images/ACEM USACH/logo2.png';
import barraUSACH from '../../images/ACEM USACH/barra.png';


//ACEM UTA
import logo1UTA from '../../images/ACEM UTA/logo1.png';
import logo2UTA from '../../images/ACEM UTA/logo2.png';
import barraUTA from '../../images/ACEM UTA/barra.png';


//CCTEM
import logo1CCTEM from '../../images/CCTEM 1/logo1.png';
import logo2CCTEM from '../../images/CCTEM 1/logo2.png';
import barraCCTEM from '../../images/CCTEM 1/barra.png';


//SCEM USS STGO
import logo1SCEM from '../../images/SCEM USS STGO/logo1.png';
import logo2SCEM from '../../images/SCEM USS STGO/logo2.png';
import barraSCEM from '../../images/SCEM USS STGO/barra.png';

//CCNEM
import logo1CCNEM from '../../images/CCNEM 1/logo1.jpg';
import logo2CCNEM from '../../images/CCNEM 1/logo2.jpg';
import barraCCNEM from '../../images/CCNEM 1/barra.jpg';


const styles = StyleSheet.create({
  seCertifica: {
    fontSize: 12,
    textAlign: 'center',
    padding:5,
    textTransform: 'uppercase',
  },
  center: {
    textAlign: 'center',
    paddingTop: 5,
    paddingRight: 10,
    //borderWidth: 3
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    textAlign: 'center',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
  },

  container2: {
    flex: 1,
    paddingTop: 30,
    paddingLeft: 120,
    paddingRight: 10,
    '@media max-width: 400': {
      paddingTop: 10,
      paddingLeft: 0,
    },
  },

  firma: {
    height: 50,
    width: 80,
    textAlign: "center",
    //marginBottom: 30,
    marginHorizontal: 60,
  },

  general: {
    paddingLeft: 15,
    paddingRight: 15,  
    paddingTop: 10,
    fontSize: 10
  },

  pageBackground: {
    left: 0,
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '15%',
  },

  imageAnacem: {
    height: 65,
    width: 220,
    //marginBottom: 30,
    marginHorizontal: 30,
  },

  image: {
    height: 90,
    width: 140,
    //marginBottom: 30,
    marginHorizontal: 30,
  },
  
  nombre: {
    fontSize: 15,
    fontWeight: 900,
  },

  page: {
    flexDirection: 'column',
    padding: 30,
  },
  qr_validar: {
    height: 55,
    width: 55,
    marginHorizontal: 30,
    paddingLeft: 0,
  },
  rut: {
    marginTop:5,
    fontSize: 14,
    color: 'black',
    fontWeight: "heavy"
  },
  
  table: { 
    paddingTop: 20,
    display: "table", 
    width: "auto", 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol: { 
    width: "25%", 
    borderStyle: "solid", 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellDescarga:{ 
    cell: { 
      marginTop: 5, 
      fontSize: 6,
      display: 'block',
      width: '100%',
      textAlign: 'center'
    },
    nombre: {
      marginTop: 5, 
      fontWeight:900,
      fontSize: 8,
      color: 'red',
      display: 'block',
      width: '100%',
      textAlign: 'center'
    }
  },
  tableCellBD: {
    cell: { 
      marginTop: 5, 
      fontSize: 8,
      display: 'block',
      width: '100%',
      textAlign: 'center'
    },
    firma_2:{
      marginTop:5,
      display: 'block',
      width: '100%',
      textAlign: 'center',
      fontSize:8,
      whiteSpace:"nowrap" ,

    },
    nombre: {
      marginTop: 5, 
      fontWeight:900,
      fontSize: 8,
      color: 'red',
      display: 'block',
      width: '100%',
      textAlign: 'center'
    },
  },
  validador: {
    fontSize: 8,
    textAlign: 'left',
    marginHorizontal: 30,
    paddingTop: 5,
    paddingLeft: 5,
  }
  
});

const cantidadMeses = (numMeses, meses, dias, ano, horas) => {

  if (numMeses === '1'){
    return(
    <Text style={styles.general}>
      realizado los días {dias.dias_mes_1} de {meses.mes_1} de {ano}, con {horas} horas pedagógicas.
    </Text>)
  }
  if (numMeses === '2'){
    return(
    <Text style={styles.general}>
      realizado los días {dias.dias_mes_1} de {meses.mes_1} y {dias.dias_mes_2} de {meses.mes_2} de {ano}, con {horas} horas pedagógicas.
    </Text>)
  }
  if (numMeses === '3'){
    return(
    <Text style={styles.general}>
      realizado los días {dias.dias_mes_1} de {meses.mes_1}, {dias.dias_mes_2} de {meses.mes_2} y {dias.dias_mes_3} de { meses.mes_3} de {ano}, con {horas} horas pedagógicas.
    </Text>)
  }
}

const universidadZonal = (academia, universidad) => {
  if (academia === "Zonal"){
    return(
      <Text style={styles.general}>Organizado por { academia } {universidad}</Text>)
  }else {
    return(
      <Text style={styles.general}>Organizado por { academia } de 
      Estudiantes de Medicina de la {universidad}</Text>)
  }

}


const cantidadFirmas = (numFirmas, firmas, estilo, validar) => {
  console.log(parseInt(numFirmas));
  let n = parseInt(numFirmas);
  if ( n === 0 ) {
    return
  } else if ( n === 1 ){

    return(
      <>
      <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Image
            src={firmas.firma1url}
            style={styles.firma}
            alignItems='center'       
            />
          </View> 
          
        </View>
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={estilo.nombre}>{ firmas.nombreFirma1 }</Text> 
          </View> 
          
        </View> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            {firmas.cargo1.length <= 23 ? <Text style={estilo.cell}>{ firmas.cargo1 }</Text> : <Text style={estilo.firma_2}>{ firmas.cargo1 }</Text> }
          </View>  
          
        </View> 
      </View>
      {/* {generarQR(validar)} */}
      </>
    )
  } else if ( n === 2 ) {

    return(
      <>
      <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Image
            src={firmas.firma1url}
            style={styles.firma}
            alignItems='center'       
            />
          </View> 
          <View style={styles.tableCol}> 
            <Image
            src={firmas.firma2url}
            style={styles.firma}
            alignItems='center'       
            />
          </View> 
          
        </View>
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={estilo.nombre}>{ firmas.nombreFirma1 }</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={estilo.nombre}>{ firmas.nombreFirma2 }</Text> 
          </View> 
          
        </View> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
          {firmas.cargo1.length <= 23 ? <Text style={estilo.cell}>{ firmas.cargo1 }</Text> : <Text style={estilo.firma_2}>{ firmas.cargo1 }</Text> }
          </View> 
          <View style={styles.tableCol}> 
          {firmas.cargo2.length <= 23 ? <Text style={estilo.cell}>{ firmas.cargo2 }</Text> : <Text style={estilo.firma_2}>{ firmas.cargo2 }</Text> }
          </View> 
          
        </View> 
      </View>

      {/* {generarQR(validar)} */}
    </>
    )

  } else if ( n >= 3 ){

    return(
      <>
      <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Image
            src={firmas.firma1url}
            style={styles.firma}
            alignItems='center'       
            />
          </View> 
          <View style={styles.tableCol}> 
            <Image
            src={firmas.firma2url}
            style={styles.firma}
            alignItems='center'       
            />
          </View> 
          <View style={styles.tableCol}> 
            <Image
            src={firmas.firma3url}
            style={styles.firma}
            alignItems='center'       
            />
          </View> 
          
        </View>
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={estilo.nombre}>{ firmas.nombreFirma1 }</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={estilo.nombre}>{ firmas.nombreFirma2 }</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={estilo.nombre}>{ firmas.nombreFirma3 }</Text> 
          </View> 
          
        </View> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
          {firmas.cargo1.length <= 23 ? <Text style={estilo.cell}>{ firmas.cargo1 }</Text> : <Text style={estilo.firma_2}>{ firmas.cargo1 }</Text> }
          </View> 
          <View style={styles.tableCol}> 
          {firmas.cargo2.length <= 23 ? <Text style={estilo.cell}>{ firmas.cargo2 }</Text> : <Text style={estilo.firma_2}>{ firmas.cargo2 }</Text> }
          </View> 
          <View style={styles.tableCol}> 
          {firmas.cargo3.length <= 23 ? <Text style={estilo.cell}>{ firmas.cargo3 }</Text> : <Text style={estilo.firma_2}>{ firmas.cargo3 }</Text> }

          </View> 
          
        </View> 
      </View>

    {/* {generarQR(validar)} */}
    </>
    )
  }
  
}



const firmasExtra = (numFirmas, firmas, estilo, validar) => {
   if ( numFirmas === '4' ){
    return(
      <>
     
      <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
              <Image
              src={firmas.firma4url}
              style={styles.firma}
              alignItems='center'       
              />
            </View> 
                
        </View>
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={estilo.nombre}>{ firmas.nombreFirma4 }</Text> 
          </View> 
          
        </View> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
          {firmas.cargo4.length <= 23 ? <Text style={estilo.cell}>{ firmas.cargo4 }</Text> : <Text style={estilo.firma_2}>{ firmas.cargo4 }</Text> }
          </View> 
         
          
        </View> 
      </View>

       {/* {generarQR(validar)} */}

      </>
    )
  } else if ( numFirmas === '5' ){
    return(
      <>
    

      <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Image
            src={firmas.firma4url}
            style={styles.firma}
            alignItems='center'       
            />
          </View> 
          <View style={styles.tableCol}> 
            <Image
            src={firmas.firma5url}
            style={styles.firma}
            alignItems='center'       
            />
          </View> 
          
        </View>
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={estilo.nombre}>{ firmas.nombreFirma4 }</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={estilo.nombre}>{ firmas.nombreFirma5 }</Text> 
          </View> 
          
        </View> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
          {firmas.cargo4.length <= 23 ? <Text style={estilo.cell}>{ firmas.cargo4 }</Text> : <Text style={estilo.firma_2}>{ firmas.cargo4 }</Text> }
          </View> 
          <View style={styles.tableCol}> 
          {firmas.cargo5.length <= 23 ? <Text style={estilo.cell}>{ firmas.cargo5 }</Text> : <Text style={estilo.firma_2}>{ firmas.cargo5 }</Text> }
          </View> 
          
        </View> 
      </View>
     
       {/* {generarQR(validar)} */}
      </>
    )
  }else {
    return (
      <>
      <View style={{padding:45}}>
      </View>
      </>
    );
  }

}

const plantillas = (plantillas, logo, logo1=0, logo2=0) => {
  switch (plantillas) {
    case '0':
      if (logo === '0') {
        return(
          <View style={styles.tableRow}> 
            <View > 
              <Image
              src={anacem}
              style={styles.imageAnacem}
              alignItems='center'       
              />
            </View>  
          </View>
        )
      } else {
        return(
          <View style={styles.tableRow}> 
            <View > 
              <Image
              src={logo1}
              style={styles.image}
              alignItems='center'       
              />
            </View> 
            <View > 
              <Image
              src={anacem}
              style={styles.imageAnacem}
              alignItems='center'       
              />
            </View>  
            <View > 
              <Image
              src={logo2}
              style={styles.image}
              alignItems='center'       
              />
            </View> 
          </View>
        )
      }
  
    case '1':
      return(
        <View style={styles.tableRow}> 
          <View > 
            <Image
            src={logo1UA}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
          <View > 
            <Image
            src={anacem}
            style={styles.imageAnacem}
            alignItems='center'       
            />
          </View>  
          <View > 
            <Image
            src={logo2UA}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
        </View>
      )

    case '2':
      return(
        <View style={styles.tableRow}> 
          <View > 
            <Image
            src={logo1UAT}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
          <View > 
            <Image
            src={anacem}
            style={styles.imageAnacem}
            alignItems='center'       
            />
          </View>  
          <View > 
            <Image
            src={logo2UAT}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
        </View>
      )

    case '3':
      return(
        <View style={styles.tableRow}> 
          <View > 
            <Image
            src={logo1UCSC}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
          <View > 
            <Image
            src={anacem}
            style={styles.imageAnacem}
            alignItems='center'       
            />
          </View>  
          <View > 
            <Image
            src={logo2UCSC}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
        </View>
      )

    case '4':
      return(
        <View style={styles.tableRow}> 
          <View > 
            <Image
            src={logo1UFT}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
          <View > 
            <Image
            src={anacem}
            style={styles.imageAnacem}
            alignItems='center'       
            />
          </View>  
          <View > 
            <Image
            src={logo2UFT}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
        </View>
      )

    case '5':
      return(
        <View style={styles.tableRow}> 
          <View > 
            <Image
            src={logo1UMAG}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
          <View > 
            <Image
            src={anacem}
            style={styles.imageAnacem}
            alignItems='center'       
            />
          </View>  
          <View > 
            <Image
            src={logo2UMAG}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
        </View>
      )

    case '6':
      return(
        <View style={styles.tableRow}> 
          <View > 
            <Image
            src={logo1UMT}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
          <View > 
            <Image
            src={anacem}
            style={styles.imageAnacem}
            alignItems='center'       
            />
          </View>  
          <View > 
            <Image
            src={logo2UMT}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
        </View>
      )

    case '7':
      return(
        <View style={styles.tableRow}> 
          <View > 
            <Image
            src={logo1UNABVIÑA}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
          <View > 
            <Image
            src={anacem}
            style={styles.imageAnacem}
            alignItems='center'       
            />
          </View>  
          <View > 
            <Image
            src={logo2UNABVIÑA}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
        </View>
      )

    case '8':
      return(
        <View style={styles.tableRow}> 
          <View > 
            <Image
            src={logo1USACH}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
          <View > 
            <Image
            src={anacem}
            style={styles.imageAnacem}
            alignItems='center'       
            />
          </View>  
          <View > 
            <Image
            src={logo2USACH}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
        </View>
      )

    case '9':
      return(
        <View style={styles.tableRow}> 
          <View > 
            <Image
            src={logo1UTA}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
          <View > 
            <Image
            src={anacem}
            style={styles.imageAnacem}
            alignItems='center'       
            />
          </View>  
          <View > 
            <Image
            src={logo2UTA}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
        </View>
      )

    case '10':
      return(
        <View style={styles.tableRow}> 
          <View > 
            <Image
            src={logo1CCTEM}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
          <View > 
            <Image
            src={anacem}
            style={styles.imageAnacem}
            alignItems='center'       
            />
          </View>  
          <View > 
            <Image
            src={logo2CCTEM}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
        </View>
      )

    case '11':
      return(
        <View style={styles.tableRow}> 
          <View > 
            <Image
            src={logo1SCEM}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
          <View > 
            <Image
            src={anacem}
            style={styles.imageAnacem}
            alignItems='center'       
            />
          </View>  
          <View > 
            <Image
            src={logo2SCEM}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
        </View>
      )
    
      case '12':
      return(
        <View style={styles.tableRow}> 
          <View > 
            <Image
            src={logo1CCNEM}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
          <View > 
            <Image
            src={anacem}
            style={styles.imageAnacem}
            alignItems='center'       
            />
          </View>  
          <View > 
            <Image
            src={logo2CCNEM}
            style={styles.image}
            alignItems='center'       
            />
          </View> 
        </View>
      )
    
      

    default:
      break;
  }

}

const sideBar = (plantillas, barra, imagenBarra) => {
  switch (plantillas) {

    case '0':
      if ( barra !== '0' ){
        return(
          <Image src={ imagenBarra } style={ styles.pageBackground } />
        )
      } else {
        break;
      }
      
     
    case '1':
      return(
        <Image src={barraUA} style={ styles.pageBackground } />
      )

    case '2':
      return(
        <Image src={barraUAT} style={ styles.pageBackground } />
      )

    case '3':
      return(
        <Image src={barraUCSC} style={ styles.pageBackground } />
      )

    case '4':
      return(
        <Image src={barraUFT} style={ styles.pageBackground } />
      )

    case '5':
      return(
        <Image src={barraUMAG} style={ styles.pageBackground } />
      )

    case '6':
      return(
        <Image src={barraUMT} style={ styles.pageBackground } />
      )

    case '7':
      return(
        <Image src={barraUNABVIÑA} style={ styles.pageBackground } />
      )

    case '8':
      return(
        <Image src={barraUSACH} style={ styles.pageBackground } />
      )

    case '9':
      return(
        <Image src={barraUTA} style={ styles.pageBackground } />
      )

    case '10':
      return(
        <Image src={barraCCTEM} style={ styles.pageBackground } />
      )

    case '11':
      return(
        <Image src={barraSCEM} style={ styles.pageBackground } />
      )
    
    case '12':
      return(
        <Image src={barraCCNEM} style={ styles.pageBackground } />
      )
      

    default:
      break;
  }

}

const CrearCertificado = ({ nombre, rut, horas, nota, actividad, tipo, data, validar }  ) => {

  const {universidad, numMeses, meses, dias, ano, numero, academia, logo, logo1, logo2, plantilla, numFirmas, firmas, barra, imagenBarra} = data;
  let estilo;

    if (tipo) {
        estilo = styles.tableCellDescarga;
    } else {
        estilo = styles.tableCellBD;
    }

  return (
    <Page orientation="landscape" size="A4" >
      { sideBar(plantilla, barra, imagenBarra) }
      <View style={styles.container2}>
        <View >
          { plantillas(plantilla, logo, logo1, logo2) }
          <View style={styles.center}>
            <Text style={styles.seCertifica}>Se certifica que</Text>
            <Text style={styles.nombre}>{nombre}</Text>
            <Text style={styles.rut}>RUT: {rut}</Text>
            <Text style={styles.general}>
                Ha aprobado el </Text>
            <Text style={styles.general}>
              {numero}° Curso: { actividad } </Text>
            <Text style={styles.general}>
              con nota { nota }
            </Text>
            
            {universidadZonal(academia, universidad)}

            {/* <Text style={styles.general}>Organizado por { academia } de 
                Estudiantes de Medicina de la {universidad}</Text> */}
                
                {cantidadMeses(numMeses, meses, dias, ano, horas)}
            {/* <Text style={styles.general}>
              realizado los días {dia} de {mes} de {ano}, con {horas} horas pedagógicas.
             
            </Text> */}
            <Text style={styles.general}>
            Esta actividad no forma parte de programas de instituciones de pregrado ni constituye requisitos de titulación.
            </Text>

           

          </View>
          
          {/* <View>
            <Text style={styles.validador}>{validar}</Text>
            <Image src={qr} style={ styles.qr_validar } />
          </View> */}
          
          { cantidadFirmas(numFirmas,firmas, estilo, validar) }

          { firmasExtra(numFirmas,firmas, estilo, validar) }
        </View>
        <View> 
        <Text style={styles.validador}>{validar}</Text>
        <Image src={qr} style={ styles.qr_validar } />
      </View>
      </View>
    </Page>  
  );
};

export const certificadosBD = ( nombre, rut, horas, nota, actividad, data, validar ) => (     // Genera un certificado por persona y lo va guardando en la BD a medida que va iterando los datos
    <Document>
      <CrearCertificado
        nombre={nombre}
        rut={rut}
        horas={horas}
        nota={nota}
        actividad={actividad}
        tipo={false}
        data={data}
        validar={validar}
        key={nombre + rut} 
      /> 
    </Document>
  )




