


export const types = {

    login: '[Auth] Login',
    logout: "[Auth] Log out",

    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',
    uiStartLoading: '[UI] Start Loading',
    uiFinishLoading: '[UI] Finish Loading',

    dataLoad: '[Load] Data Load',
    dataDelete: '[Delete] Data Delete',
    

}

