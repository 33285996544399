import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
  Font
} from "@react-pdf/renderer";
import qr from "../../images/qr_anacem.png";

Font.register({
    family: 'Roboto',
    fonts: [
      { src: 'https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Regular.woff' },
      { src: 'https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Bold.woff', fontWeight: 700 }
    ]
  })

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    flexDirection: "col",
    fontWeight: "light"
  },
  pageBackground: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%"
  },
  nombre: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 90,
    fontSize: 34,
    color: "#817f83"
  },
  rut: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
    fontSize: 20,
    color: "#9FA39D"
  },
  nota: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 72,
    fontSize: 20,
    color: "#9FA39D"
  },
  qr_validar: {
    height: 75,
    width: 75,
    marginTop: 50,
    marginRight: 55,
    marginLeft: "auto"
  },
  qr_texto: {
    marginLeft: "auto",
    marginRight: 49,
    marginTop: 3,
    color: "white",
    fontSize: 11
  },
  textoNota: {
    display: "flex",
    marginHorizontal: "auto",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
    textAlign: "center",
    width: "100%",
    paddingHorizontal: 56,
    fontSize: 10,
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: "#00000"
  }
});

export default async function Certificado(data) {
  const blob = await pdf(createPDF(data)).toBlob();
  return blob;
}

function createPDF(data) {
  console.log(data);
  const { nombre, rut, nota, qrCode, baseImage, notaOTexto } = data;

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.page}>
        <Image
          src={URL.createObjectURL(baseImage)}
          style={styles.pageBackground}
        />
        <View>
          <Image src={qr} style={styles.qr_validar} />
          <Text style={styles.qr_texto}>
            {" "}{qrCode}{" "}
          </Text>
        </View>
        <View>
          <Text style={styles.nombre}>
            {nombre}
          </Text>
          <Text style={styles.rut}>
            RUT: {rut}
          </Text>
        </View>
        <View>
          {notaOTexto === "nota"
            ? <Text style={styles.nota}>
                {nota}
              </Text>
            : <Text style={styles.textoNota}>
                {nota}
              </Text>}
        </View>
      </Page>
    </Document>
  );
}
