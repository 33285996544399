import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, doc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "react-app-certificados.firebaseapp.com",
    databaseURL: "https://react-app-certificados.firebaseio.com",
    projectId: "react-app-certificados",
    storageBucket: "react-app-certificados.appspot.com",
    messagingSenderId: "1049023695516",
    appId: "1:1049023695516:web:89cfac23ea7439ee5bfb59"
};

firebase.initializeApp(firebaseConfig);
  // Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

const uploadFile = async (data) => {
  const d = new Date();
  let time = d.getTime();
  const { actividad, rut, qrCode, file, nombre, reference } = data

  const certificadosRef = ref(storage, `gs://react-app-certificados.appspot.com/${reference}/${time}.pdf`);
  const response = {};

  try {
      const result = await uploadBytes(certificadosRef, file);
      const downloadURL = await getDownloadURL(certificadosRef);
      const docRef = await addDoc(collection(db, "certificados"), {
        rut,
        nombre,
        actividad,
        url: downloadURL,
        estado: 'Válido',
        validar: qrCode,
        created_at: new Date().getTime()
      });
      result.docRef = docRef;
  } catch (e) {
      console.log(e)
  }

  return response;
}

const deleteDocById = async (docId) => {
  try {
    await deleteDoc(doc(db,"certificados", docId))
  } catch (error){
    console.log(error.message)
  }
}

export {
    db,
    firebase,
    storage,
    uploadFile,
    deleteDocById
}
