import React from 'react';
import { useDispatch } from 'react-redux';
import { startLogout } from '../../actions/auth'
import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
//import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import './custom-bar.css'
import logo from './logo.png'

export const Navebar = (type) => {

    const dispatch = useDispatch();

    const handleLogout = () => {

        dispatch( startLogout() );

    }
    

    return (
        <>
            <Navbar className="nav-bar-auth" style={{}}>
                <Navbar.Brand > <img src={logo} alt="ANACEM" width="200px"></img></Navbar.Brand>
                <Nav.Link className="navbar-link" href="/">Crear Certificados</Nav.Link>
                <Nav.Link className="navbar-link" href="/certificados-personalizados">Certificados Personalizados</Nav.Link>

                <Nav.Link className="navbar-link" href="/eliminar_certificado">Eliminar Certificados por RUT</Nav.Link>
                <Nav.Link className="navbar-link" href="/eliminar_certificado_por_actividad">Eliminar Certificados por Actividad</Nav.Link>
                <Navbar.Collapse className="justify-content-end">
                    <Button className="navbar-link" variant="outline-info" onClick={ handleLogout } >  <i className="fas fa-sign-out-alt"></i> Cerrar Sesión</Button>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

