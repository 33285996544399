import React, { useState } from 'react';
import { Navebar } from '../ui/Navebar';
import Table from 'react-bootstrap/Table'
import './styles.css';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import Linkify from 'react-linkify';
import { getCertificadosByRut } from '../../helpers/getCertificadosAndValidations';
import { deleteDocById } from '../../firebase/firebaseConfig';

export const ModificarCertificados = () => {
    const [rut, setRut] = useState('');
    const [resultados, setResultados] = useState([]);
    const [showResults, setShowResults] = useState(false);

    const handleSetRut = (event) => setRut(event.target.value);

    const buscarCertificados = async () => {
        const certificados = await getCertificadosByRut(rut);

        setResultados(certificados)
        setShowResults(true)
    }

    const eliminarCertificado = async (certificado) => {
        console.log(certificado)
        try{
            await deleteDocById(certificado.id)
            await buscarCertificados()
        } catch (error){
            console.log(error)
        }
        // Swal.fire({
        //     title: 'Estás seguro?',
        //     text: "Recuerda que esta decisión no se puede revertir",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Sí, elimínalos!'
        // }).then(async(result) => {
        //     if (result.isConfirmed) {
        //         console.log(certificado.id)
        //         try{
        //             await deleteDocById(certificado.id)
        //         Swal.fire(
        //             'Eliminado',
        //             'El certificado ha sido eliminado.',
        //             'success'
        //         )
        //         buscarCertificados()
        //         } catch (error){
        //             Swal.fire(
        //                 'Hubo un error',
        //                 'Intenta más tarde',
        //                 'error'
        //             )
        //         }
        //     }
        // })
    }

    return (
        <>
            <Navebar />
            <div className="custom_div">
                <input className="search-field"
                    type="text"
                    name="rut"
                    placeholder={"Ingresa el RUT"}
                    autoComplete="off"
                    value={rut}
                    onChange={(event) => handleSetRut(event)}
                />
                <Button style={{
                    marginLeft: "auto",
                    marginTop: 30,
                    marginBottom: 30,
                    justifyContent: "center",
                    marginRight: "auto",
                    width: '100%',
                    color: 'white',
                    fontWeight: '700',
                    fontSize: '1.2em',
                    backgroundColor: '#B91C3D',
                    padding: '15px',
                    borderRadius: '15px',
                }} onClick={() => buscarCertificados()}>Buscar</Button>

                {
                    showResults ?
                        resultados.length > 0 ?
                            <Table id='personas' text-align="center" responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                        <th>RUT</th>
                                        <th>ACTIVIDAD</th>
                                        <th>CÓDIGO</th>
                                        <th>LINK</th>
                                        <th>BORRAR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resultados.length > 0 && resultados.map(certificado =>
                                        <tr key={`${certificado.rut}-${certificado.validar}`}>
                                            <td>{certificado.nombre}</td>
                                            <td>{certificado.rut}</td>
                                            <td>{certificado.actividad}</td>
                                            <td>{certificado.validar}</td>
                                            <td><Linkify><a href={certificado.url} target="_blank" rel="noopener noreferrer" > Ver Certificado</a> </Linkify></td>
                                            <td> <Button onClick={() => eliminarCertificado(certificado)}> 🗑 Eliminar </Button></td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            :
                            <p> No se encontraron resultados </p>
                        : null
                }
            </div>
        </>
    )
}
