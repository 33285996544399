

export const universidades = [
    '',
    'Universidad de Tarapacá',
    'Universidad de Antofagasta',
    'Universidad de Atacama',
    'Universidad Católica del Norte',
    'Universidad Autónoma de Chile Providencia',
    'Universidad Andrés Bello Santiago',
    'Universidad del Desarrollo',
    'Universidad de los Andes',
    'Universidad de Santiago de Chile',
    'Universidad Diego Portales',
    'Universidad Finis Terrae',
    'Universidad Mayor Santiago',
    'Universidad Pedro de Valdivia',
    'Universidad San Sebastián Santiago',
    'Universidad Andrés Bello Viña del Mar',
    'Universidad Autónoma de Chile Talca',
    'Universidad de Talca',
    'Universidad Católica de la Santísima Concepción',
    'Universidad San Sebastián Concepción',
    'Universidad Andrés Bello Concepción',
    'Universidad de la Frontera',
    'Universidad Mayor Temuco',
    'Universidad Austral de Chile',
    'Universidad San Sebastián Puerto Montt',
    'Universidad de Magallanes',
]

export const zonales = [
    '',
    'Norte',
    'Centro',
    'Metropolitano',
    'Sur',
]

export const meses = [
    '',
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
]

export const plantillas = [
    {
        plantilla:'', 
        num: 0
    },
    {
        plantilla: 'ACEM UA',
        num: 1
    },
    {
        plantilla: 'ACEM UAT',
        num: 2
    },
    {
        plantilla: 'ACEM UCSC',
        num: 3
    },
    {
        plantilla: 'ACEM UFT',
        num: 4
    },
    {
        plantilla: 'ACEM UMAG',
        num: 5
    },
    {
        plantilla: 'ACEM UMT',
        num: 6
    },
    {
        plantilla: 'ACEM UNAB VIÑA',
        num: 7
    },
    {
        plantilla: 'ACEM USACH',
        num: 8
    },
    {
        plantilla: 'ACEM UTA',
        num: 9
    },
    {
        plantilla: 'CCTEM 1',
        num: 10
    },
    {
        plantilla: 'SCEM USS SANTIAGO',
        num: 11
    },
    {
        plantilla: 'CCNEM 1',
        num: 12
    }
]


