import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect
  } from 'react-router-dom';

import { useDispatch } from 'react-redux'

import './App.css';
import { firebase } from '../firebase/firebaseConfig'
import { AuthRouter } from './AuthRouter';
import { login } from '../actions/auth';
import { MainPage } from '../components/mainpage/mainPage';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { privateMainPage } from '../components/mainpage/privateMainPage';
import {ModificarCertificados} from '../components/delegado/ModificarCertificado';
import {EliminarCertificadosPorActividad} from '../components/delegado/EliminarCertificadosPorActividad';

import CustomCertificados from '../components/CustomCertificados/customCertificados';

export const AppRouter = () => {

    const dispatch = useDispatch();

    const [checking, setChecking] = useState(true);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {

        firebase.auth().onAuthStateChanged(async (user) => {

            if ( user?.uid ) {
                dispatch( login( user.uid, user.email ) );
                setIsLoggedIn( true );
            } else {
                setIsLoggedIn( false );
            }

            setChecking( false );
        } );
            
        }
    , [ dispatch, setChecking, setIsLoggedIn ])

    if ( checking ) {
        return(<div className="App">
        <header className="App-header">
          <p>
          Cargando...
          </p>
        
        </header>
      </div>
        )
    }


    return (
        <Router>
            <div>
                <Switch>
                    <PublicRoute 
                        exact
                        path="/" 
                        component={ MainPage }
                        isAuthenticated={ isLoggedIn } 
                    />

                    <PublicRoute
                        exact 
                        path="/auth/login"
                        component={ AuthRouter }
                        isAuthenticated={ isLoggedIn }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ isLoggedIn }
                        path="/generar_certificado"
                        component={ privateMainPage }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ isLoggedIn }
                        path="/certificados-personalizados"
                        component={ CustomCertificados }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ isLoggedIn }
                        path="/eliminar_certificado"
                        component={ ModificarCertificados }
                    />

<PrivateRoute 
                        exact
                        isAuthenticated={ isLoggedIn }
                        path="/eliminar_certificado_por_actividad"
                        component={ EliminarCertificadosPorActividad }
                    />

                    <Redirect to="/" />


                </Switch>
            </div>
        </Router>
    )
}
