import React, { useState } from 'react';
import { NavebarPublic } from '../ui/NavebarPublic';
import Linkify from 'react-linkify';
import { Layout, Card, Col, Row, Button } from 'antd';
import './antd_custom.css';
import './custom-main.css';
import Swal from 'sweetalert2';
import { getCertificadosByRut, validateCertificadosByCode } from '../../helpers/getCertificadosAndValidations';

export const MainPage = () => {
    const [rut, setRut] = useState('');
    const [filteredPerson, setFilteredPerson] = useState([]);
    const { Footer } = Layout;


    const validarCertificado = () => {
        Swal.fire({
            title: 'Validar Certificados',
            icon: 'info',
            input: 'text',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Validar',
            cancelButtonText: 'Cerrar',
            html: 'Ingrese el código de validación para comprobar si el certificado existe',
            preConfirm: async (code) => {
                const aux = await validateCertificadosByCode(code);
                if (aux.result) {
                    Swal.fire('Buenas noticias!', 'El certificado es válido y le pertenece al rut: ' + aux.rut, 'success');
                } else {
                    Swal.fire('Ups!', 'No existe un certificado asociado al número de validación ingresado', 'error');
                }
            }
        })

    }

    const tableData = (filteredPerson) => {
        return filteredPerson.map((persona) => {
            return (
                <tr key={persona.id}>
                    <td>{persona.rut}</td>
                    <td>{persona.actividad}</td>
                    <td><Linkify><a href={persona.url} target="_blank" rel="noopener noreferrer" > Ver Certificado</a> </Linkify></td>
                </tr>
            )
        })
    }

    const searchData = async () => {
        const filtered = await getCertificadosByRut(rut);

        setFilteredPerson(filtered)
    }

    return (
        <>
            <NavebarPublic />

            <Row justify="center">

                <Card bordered={false}>
                    <p className="titulos"> Bienvenido al Sistema de Certificados de ANACEM. <br></br>Para buscar un certificado, ingresa tu RUT siguiendo el siguiente formato:
                        XX.XXX.XXX-X</p>
                    <Col justify="center">
                        <input className="search-field"
                            type="text"
                            name="rut"
                            placeholder={"12.345.678-9"}
                            autoComplete="off"
                            value={rut}
                            onChange={(e) => setRut(e.target.value)}
                        />
                        <Button disabled={rut.length < 8} onClick={() => searchData()}> Buscar </Button>
                    </Col>
                </Card>
            </Row>
            <Row justify="center">
                <p className="resultados_titulo" id='title'>A continuación se mostrarán los resultados:</p>
            </Row>
            <Row justify="center">
                {filteredPerson.length > 0 &&
                    <div key='principal'>
                        <table id='personas' text-align="center" className="table" >
                            <tbody>
                                <tr>
                                    <th>RUT</th>
                                    <th>ACTIVIDAD</th>
                                    <th>URL</th>
                                </tr>
                                {tableData(filteredPerson)}

                            </tbody>
                        </table>
                    </div>
                }
            </Row>

            <Row justify="center">
                <Card bordered={false}>            <button className="boton_validar" onClick={validarCertificado} >¿Quieres Validar un Certificado?</button>
                </Card>
            </Row>
            <div style={{ padding: '30px' }}>
                <p style={{ color: 'red' }}>ATENCIÓN:</p>
                <ul>
                    <li>Recuerda estar seguro de que los organizadores de la actividad donde participaste crearon los certificados usando esta plataforma, de lo contrario no lo encontrarás aquí y deberás solicitarlo directamente con ellos.</li>
                    <li>Esta plataforma comenzó a funcionar a inicios de 2021, por lo que es probable que solo encuentres certificados de actividades desde esa fecha en adelante. Para información de certificados anteriores contacta a la mesa directiva de ANACEM.</li>
                </ul>
            </div>
            <Footer style={{ marginTop: 'auto', marginBottom: '0px', textAlign: 'center' }}>Iniciativa impulsada por la Directiva de ANACEM 2019-2020 para todos sus miembros ❤
                <br>
                </br>
                <a href="mailto:presidente@anacem.cl"> Contacto ANACEM </a>
            </Footer>
        </>
    )
}






