import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

export const PublicRoute = ({
    isAuthenticated,
    component: Component,
    isClient,
    ...rest
}) => {
    return (
        <Route {...rest}
            component={ (props) => (
                ( isAuthenticated ) 
                    ?(<Redirect to='/generar_certificado' />)
                    :(<Component {...props} />)
                    
            )}
        />
    )
}

PublicRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isClient: PropTypes.bool,
    component: PropTypes.func.isRequired
}