import Swal from 'sweetalert2';

import { firebase } from '../firebase/firebaseConfig'
import { types } from "../types/types"
import { startLoading, finishLoading } from './ui'


export const startLogin = ( email, password ) => {
    return(dispatch) => {

        dispatch( startLoading() );

        firebase.auth().signInWithEmailAndPassword( email, password )
            .then( ({ user }) => {
                dispatch( login( user.uid, user.email ) );

                dispatch( finishLoading() );
            })
            .catch( e => {
                console.log(e);
                dispatch( finishLoading() );
                Swal.fire('Error', 'Correo o contraseña incorrectos', 'error')
            })
    }
}

export const login = (uid, displayEmail) => ({
        type: types.login,
        payload: {
            uid,
            displayEmail
    
    }})

export const startLogout = () => {
    return async(dispatch) => {
        await firebase.auth().signOut();

        dispatch( logout() );
    }
}

export const logout = () => ({
    type: types.logout
})





