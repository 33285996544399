import React from 'react';
import logo from './logo.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { startLogin } from '../../actions/auth';


import "./custom.css";

export const LoginScreen = () => {

    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.ui)

    const [ formValues, handleInputChange ] = useForm({
        email: '',
        password: ''
    });

    const { email, password } = formValues;

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch( startLogin( email, password ));
    }

    return (
        <>
        
         <img
        alt="Logo"
        className="image_auth"
        src={logo}
        />
            <h3 className="titulo-auth">¡Bienvenido!</h3>

            <p className="text_auth">Al acceder a este espacio tendrás la responsabilidad como representante de una Academia de generar los certificados para los miembros de ANACEM.</p>
            <br>
            </br>
            <form onSubmit={ handleLogin }>

                <input 
                    type="text"
                    placeholder="Email"
                    name="email"
                    className="input-auth"
                    autoComplete="off"
                    value={ email }
                    onChange={ handleInputChange }
                />

                <input 
                    type="password"
                    placeholder="Contraseña"
                    name="password"
                    className="input-auth"
                    value= { password }
                    onChange={ handleInputChange }
                />


                <button
                    type="submit"
                    className="boton"
                    disabled={ loading }
                >
                    Iniciar Sesión
                </button>
                

            </form>
            <form action="/">
            <button
                    type="submit"
                    className="boton_volver"
                >
                    Volver
                </button>
</form>

        </>
    )
}
