import { types } from '../types/types';



const initialState = {
    data: []
}

export const dataReducer = (state = initialState, action) => {
    console.log(action.type)
    switch (action.type) {
        case types.dataLoad:
            return {
                ...state,
                data: [...action.payload]
            }
        case types.dataDelete:
            return {
                ...state,
                data: state.data.filter( d => d.id !== action.payload)
            }
        default:
            return state;
    }


}
