import React from "react";
import { Navebar } from "../ui/Navebar";
import { useState } from "react";
import Certificado from "./Certificado";
import { generate } from 'generate-password';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { uploadFile } from "../../firebase/firebaseConfig"
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid"

const CustomCertificados = () => {
    const [baseImage, setBaseImage] = useState(null)
    const [data, setData] = useState(null)
    const [archivosGenerados, setArchivosGenerados] = useState([])
    const [actividad, setActividad] = useState('')
    const [cargando, setCargando] = useState(false)
    const [notaOTexto, setNotaOTexto] = useState('nota')
    let zip = new JSZip();

    const handleBaseImageUpload = (event) => {
        setBaseImage(event.target.files[0])
    }

    const handleCsvInputFile = async (event) => {
        const fileUrl = URL.createObjectURL(event.target.files[0]);
        const response = await fetch(fileUrl);
        const text = await response.text();
        const _data = generateData(text)
        setData(_data)
    }

    const generateData = (csv) => {
        const lines = csv.split("\n");
        var _data = [];
        lines.map((line,index) => {
            const identifier = getIdentifier(line)
            const info = line.split(identifier)

            if (info[0] === '' || info[1] === '' || info[2] === '' || info[0].toLowerCase() === 'nombre'){
                return;
            }

            if (info.length > 3){
                let _nota = ''
                for (let i=2; i<info.length; i++){
                    _nota += info[i]
                    if (i < info.length-1){
                        _nota += identifier 
                    }
                }
                info[2] = _nota
            }

            const obj = {
                nombre: info[0].toUpperCase(),
                rut: info[1],
                nota: info[2].replaceAll('"','').toUpperCase(),
                actividad: actividad
            }
            _data.push(obj)
            }
        );

        return _data
    }

    const validar = () => generate({
        lenght: 10,
        numbers: true,
        lowercase: false,
        uppercase: true,
      });

    const getIdentifier = (text) => {
        return text.split(";").length === 3 ? ";" : ","
    }

    const createCertificados = async () => {
        const results = await Promise.all(data.map( async d => {
            d.qrCode = validar()

            const file = await Certificado({baseImage, notaOTexto, ...d})
                
            return {nombre: d.nombre, rut: d.rut, file: file, qrCode: d.qrCode, reference: `0-certificados-personalizados/${actividad}/${d.rut}/${d.qrCode}`}
        }))

        setArchivosGenerados(results)
    }

    const downloadFiles = () => {
        archivosGenerados.map(archivo => {
            const id = uuidv4();
            zip.file(`${archivo.rut}-${actividad}-${id}.pdf`, archivo.file);
        })
        
        zip.generateAsync({type:'blob'}).then((zip) => {
            saveAs(zip, 'certificados.zip');
        });
    }

    const handleActividadText = (event) => {
        setActividad(event.target.value)
    }

    const saveToDatabase = async () => {
        setCargando(true)
        await Promise.all(archivosGenerados.map(async archivo => {
            archivo.actividad = actividad
            try{
                await uploadFile(archivo)
            } catch (error) {
                console.log('error:', error.message)
            }
        } ))

        Swal.fire('Listo!', 'Los certificados fueron guardados correctamente', 'success');
        setCargando(false)
    }
    
    return (
        <>
            <Navebar />
            
            <div className="p-4">
                <h3>Certificados Personalizados</h3>
                <div className="p-2">
                    <label htmlFor="actividad"> - Añade el nombre de la actividad </label>
                    <input className="mx-2" style={{width:'500px',padding:5}} type="text" onChange={handleActividadText} id="actividad" />
                </div>
                <div className="p-2">
                    <label htmlFor="baseImg"> - Selecciona el archivo que contiene el diseño base del certificado, debe ser una imagen: </label>
                    <input className="mx-2" type="file" accept="image/*" onChange={handleBaseImageUpload} id="baseImg" />
                </div>
                <div className="p-2">
                    <label htmlFor="notaOTexto"> - Usarás NOTA o TEXTO? </label>
                    <select className="mx-2" onChange={(e) => setNotaOTexto(e.target.value)} id="notaOtexto">
                        <option value={'nota'}>NOTA</option>
                        <option value={'texto'}>TEXTO</option>
                    </select>
                </div>
                <div className="p-2">
                    <label htmlFor="csvData"> - Selecciona el archivo .CSV que contiene la información para crear los certificados: </label>
                    <input className="mx-2" type="file" accept=".csv" onChange={handleCsvInputFile} id="csvData" />
                </div>
                
                { (!!baseImage && !!data && '' !== actividad) ? 
                <div className="p-2">
                    <button disabled={!baseImage} 
                    className="p-2"
                        style={{
                            marginLeft: 0,
                            marginRight: 30,
                            width: 300,
                            color: 'white',
                            fontSize: '1.2em',
                            backgroundColor: 'orange',
                            padding:'5px',
                            fontWeight: '500',
                            borderRadius: '15px',
                            paddingLeft: 0,
                            paddingRight: 0
                            }}
                        onClick={()=>createCertificados()}>
                            Generar Certificados
                    </button>
                    </div>
                : null}

                { archivosGenerados.length > 0 ? 
                   
                   <div className="p-2">
                        <button disabled={archivosGenerados.length < 1}
                        className="p-2" 
                        style={{
                            marginLeft: 0,
                            marginRight: 30,
                            width: 300,
                            color: 'white',
                            fontSize: '1.2em',
                            backgroundColor: '#109BFE',
                            padding:'5px',
                            fontWeight: '500',
                            borderRadius: '15px',
                            paddingLeft: 0,
                            paddingRight: 0
                            }} 
                        onClick={()=>downloadFiles()}
                        >Descargar Certificados</button>
                    </div>
                    : null}

                { archivosGenerados.length > 0 ? 
                   
                   <div className="p-2">
                        <button disabled={archivosGenerados.length < 1 || cargando}
                        className="p-2" 
                        style={{
                            marginLeft: 0,
                            marginRight: 30,
                            width: 300,
                            color: 'white',
                            fontSize: '1.2em',
                            backgroundColor: '#2d96e1',
                            padding:'5px',
                            fontWeight: '500',
                            borderRadius: '15px',
                            paddingLeft: 0,
                            paddingRight: 0
                            }} 
                        onClick={()=>saveToDatabase()}
                        >Guardar en Base de Datos</button>
                    </div>
                    : null}
                </div>
        </>
        
    )
}

export default CustomCertificados;
