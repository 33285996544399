import React from 'react';
import { Form, Col, Row, Button} from 'react-bootstrap';
import { CSVReader } from 'react-papaparse';
import { useForm } from '../../hooks/useForm';
import { plantillas, meses, universidades, zonales } from '../../extraInfo';

import { pdf } from '@react-pdf/renderer';
import Swal from 'sweetalert2';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { uploadFile } from '../../firebase/firebaseConfig';
import { certificadosBD } from './Certificado';
import { generate } from 'generate-password';
import { Layout} from 'antd';
import './custom_certificados.css';

const buttonRef = React.createRef()


export const DefaultCertificados = () => {
  const { Content, Footer } = Layout;

  let zip = new JSZip();

  let archivos = [];
  let claves = [];
  
  
  const [ formValues, handleInputChange ] = useForm({
    universidad: '',
    dias: {
      dias_mes_1 : '',
      dias_mes_2 : '',
      dias_mes_3 : '',
    },
    numMeses: '1',
    meses: {
      mes_1 : '',
      mes_2: '',
      mes_3: '',
    },
    ano: '',
    numero: '',
    academia: '',
    infoParticipantes: [],
    plantilla: 0,
    numFirmas: '0',
    firmas: {
      nombreFirma1: '',
      cargo1: '',
      firma1url: null,
      nombreFirma2: '',
      cargo2: '',
      firma2url: null,
      nombreFirma3: '',
      cargo3: '',
      firma3url: null,
      nombreFirma4: '',
      cargo4: '',
      firma4url: null,
      nombreFirma5: '',
      cargo5: '',
      firma5url: null,
    }
  });

  const CrearCertificados = async() => {

    Swal.fire({
      title: 'Generar Certificados',
      icon: 'info',
      showConfirmButton: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: 'Generar',
      cancelButtonText: 'Cerrar',
      html: `<span id="confirmar" >Presione el botón Generar para crear los certificados</span><br />
      <span id="Swal2-status"></span>`,
      preConfirm: async() => {
        Swal.showLoading();
        archivos = [];
        claves = [];
        const largo = formValues.infoParticipantes.length - 1;
        const status = document.getElementById("Swal2-status");
        const status2 = document.getElementById("confirmar");
        status2.textContent = "Generando documentos";
        for(let i=0;i<formValues.infoParticipantes.length;i++) {
          if (i !== 0){
            const validar = generate({
              lenght: 10,
              numbers: true,
              lowercase: true,
              uppercase: true,
            });
            claves.push(validar);
            const { nombre, rut, horas, nota, actividad } = formValues.infoParticipantes[i];
            const blob = await pdf(certificadosBD( nombre, rut, horas, nota, actividad, formValues, validar )).toBlob();
            zip.file(`${rut}-${actividad}.pdf`, blob);
            status.textContent = `Generando certificado ${i}/${largo}`;
            archivos.push(blob);
          }
        }
        Swal.fire('Éxito', 'Los certificados se han creado correctamente', 'success');
      }
    })  
  }

  const descargarCertificados = () => {

    Swal.fire({
      title: 'Descargar Certificados',
      icon: 'info',
      showConfirmButton: true,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: 'Descargar',
      cancelButtonText: 'Cerrar',
      html: `<span id="confirmar" >Presione "Descargar" para descargar los certificados</span><br />`,
      preConfirm: async() => {
        Swal.showLoading();
        const status2 = document.getElementById("confirmar");
        status2.textContent = "Descargando...";
        zip.generateAsync({type:'blob'}).then((zip) => {
          saveAs(zip, 'certificados.zip');
        });  
        Swal.fire('Éxito', 'Los certificados se han descargado correctamente', 'success');
      }
    })  
    
  }

  const guardarBD = () => {

    Swal.fire({
      title: 'Guardar certificados',
      icon: 'info',
      showConfirmButton: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      cancelButtonText: 'Cerrar',
      confirmButtonText: 'Guardar',
      html: `<span id="confirmar" >Presione el botón Generar para crear y subir los certificados
      a la base de datos</span><br />
      <span id="Swal2-status"></span>`,
      preConfirm: async(res,rej) => {
        return new Promise((resolve, reject) => {
          Swal.showLoading();
          const status = document.getElementById("Swal2-status");
          const status2 = document.getElementById("confirmar");
          status.textContent = "Part 1 is working";
          status2.textContent = "Generando documentos";
          for(let i=0;i<formValues.infoParticipantes.length;i++) {
            if (i !== 0) {
              const { nombre, rut, actividad } = formValues.infoParticipantes[i];
              let newData = {
                nombre,
                rut,
                actividad
              }

              newData.reference = `0-certificados-default/${ actividad }-${formValues.numero}-${formValues.universidad}/${ rut }`
              newData.file = archivos[i-1]
              newData.qrCode = claves[i-1]

              uploadFile(newData)  
            }            
          }
          status.textContent = "Cargando certificados";
          setTimeout(() => {
            resolve();
            Swal.fire('Guardado', 'Los certificados se han guardado correctamente', 'success');
          }, 60000);
        });
      }
    });  
  };

  const { ano, numero, numFirmas, numMeses, academia } = formValues;

  const handleOpenDialog = (e) => {
      // Note that the ref is set async, so it might be null at some point 
      if (buttonRef.current) {
        buttonRef.current.open(e);
      }
    }
    
    const handleOnFileLoad = (data) => {
      const result = Object.keys(data).map((key) => Object.values(data[key].data));
  
          
      // Se crea una lista de objetos, con el cual se generarán los certificados
  
      for(let i=0;i<result.length;i++) {
        if(result[i][0] === ""){
          break;
        } else {
          formValues.infoParticipantes.push({
            nombre: result[i][0],
            rut: result[i][1],
            horas: result[i][2],
            nota: result[i][3],
            actividad: result[i][4]
          });
        } 
      }
    }
  
    const handleOnError = (err, file, inputElem, reason) => {
      console.log(err)
    }
  
    const handleOnRemoveFile = (data) => {
      formValues.infoParticipantes = [];  
    }
  
    const handleRemoveFile = (e) => {
      // Note that the ref is set async, so it might be null at some point
      if (buttonRef.current) {
        buttonRef.current.removeFile(e);
      }
    }

  return(
      <>
      <div style={{padding:30}}> 
      <li>Recuerda que el archivo a cargar debe ser en formato .csv .</li> 
        <li>Además recuerda revisar los RUTs de los participantes,
        ya que si no siguen el formato de puntos y guiones, el miembro podría no encontrarlo nunca en el sitio a pesar de haberlo creado.</li> 
        <li>Antes de cargar a la base de datos, revisa cómo quedaron los certificados descargando el archivo generado.</li>
        <li>Cargar a la Base de Datos toma su tiempo, por favor ten paciencia y no te salgas de la página en 5 minutos al menos.</li>
        </div>
      
      <Content style={{ padding: '50px'  }}>
      <CSVReader
      ref={buttonRef}
      onFileLoad={handleOnFileLoad}
      onError={handleOnError}
      noClick
      noDrag
      onRemoveFile={handleOnRemoveFile}
      >
      {({ file }) => (
      <>
          {/* <aside
          style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 10
          }}
          > */}
      
      <Row>
        <Col>
          <Button
              type='button'
              onClick={handleOpenDialog}
              style={{
              marginLeft: 0,
              marginRight: 30,
              width: 300,
              color: 'white',
              fontSize: '1.2em',
              backgroundColor: '#109BFE',
              padding:'15px',
              fontWeight: '500',
              borderRadius: '15px',
              marginBottom: '30px',
              paddingLeft: 0,
              paddingRight: 0
              }}
          >
             CARGAR ARCHIVO CSV
          </Button>

       
         </Col>
         <div style={{padding:30}}>
          {file && file.name}
          </div>
          <Col>
          <Button
             style={{
              marginLeft: 30,
              marginRight: 30,
              fontWeight: '500',
              width: '150px',
              color: 'white',
              fontSize: '1.2em',
              backgroundColor: '#FAC700',
              padding:'5px',
              borderRadius: '15px',
              marginBottom: '30px',
              paddingLeft: 0,
              paddingRight: 0
              }}
              onClick={handleRemoveFile}
          >
              Borrar
          </Button>
    
         
          </Col>
      </Row>
        
          {/* </aside> */}


      </>
      )}
      </CSVReader>
      
      <br></br>

      <Form>

      <Form.Group as={Row} controlId="defaultID">
          <Form.Label column sm={2}>
              Certificados por defecto
          </Form.Label>
          <Col sm={3}>
            <Form.Control as="select" defaultValue="Escoja..."
              type="text"
              name="plantilla"
              onChange={ handleInputChange }
              autoComplete="off"
            >
              { plantillas.map((data) => <option key={data.num} value={data.num}>{data.plantilla}</option> ) }

            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="academia2ID">
          <Form.Label column sm={2}>
              Organizado por
          </Form.Label>
          <Col sm={3}>
            <Form.Control as="select" defaultValue="Escoja..."
              type="text"
              name="academia"
              onChange={ handleInputChange }
              autoComplete="off"
            >
              <option value="" ></option>
              <option value="Academia Científica" >Academia Científica</option>
              <option value="Sociedad Científica" >Sociedad Científica</option>
              <option value="Asociación Científica"> Asociación Científica</option>
              <option value="Zonal">Zonal</option>
                {/* <option value="Zonal"> Zonal</option> */}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="universidad2ID">
          <Form.Label column sm={2}>
              Universidad o Zonal
          </Form.Label>
          <Col sm={3}>
            <Form.Control as="select" defaultValue="Escoja..."
              type="text"
              name="universidad"
              onChange={ handleInputChange }
              autoComplete="off"
            >
              {  academia === "Zonal" ? zonales.map((uni) => <option key={uni} value={uni}>{uni}</option> ) : universidades.map((uni) => <option key={uni} value={uni}>{uni}</option> ) }

            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="numero2ID">
          <Form.Label column sm={2}>
              Número de Actividad
          </Form.Label>
          <Col sm={3}>
            <Form.Control 
            type="text" 
            name="numero" 
            autoComplete="off"
            value={ numero } 
            onChange={ handleInputChange } />
          </Col>
        </Form.Group>
        
        <Form.Group as={Row} controlId="cant_mesesID">
          <Form.Label column sm={2}>
          ¿Cuántos Meses duró el curso?
          </Form.Label>
          <Col sm={3}>
            <Form.Control as="select" defaultValue="Escoja..."
              type="text"
              name="numMeses"
              onChange={ handleInputChange }
              autoComplete="off"
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>

            </Form.Control>
          </Col>
        </Form.Group>


  


        <Form.Group as={Row} controlId="dias1ID">
          <Form.Label column sm={2}>
              Día(s) Mes 1
          </Form.Label>
          <Col sm={3}>
            <Form.Control 
              type="text"
              name="dias_mes_1"
              placeholder={"11-12-13"}
              autoComplete="off"
              // value={ dia }
              onChange={ (e) => formValues.dias.dias_mes_1 = e.target.value }

            />
          </Col>
        </Form.Group>
      
        
        
        

        <Form.Group as={Row} controlId="meses1ID">
          <Form.Label column sm={2}>
              Mes 1
          </Form.Label>
          <Col sm={3}>
            <Form.Control as="select" defaultValue="Escoja..."
              type="text"
              name="mes_1"
              onChange={ (e) => formValues.meses.mes_1 = e.target.value }
              autoComplete="off"
            >
              { meses.map((mes) => <option key={mes} value={mes}>{mes}</option> ) }

            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="dias2ID">
          <Form.Label column sm={2}>
              Día(s) Mes 2
          </Form.Label>
          <Col sm={3}>
            <Form.Control 
              type="text"
              name="dias_mes_2"
              disabled={ numMeses <= 1 }
              placeholder={"14-15-16"}
              autoComplete="off"
              // value={ dia }
              onChange={ (e) => formValues.dias.dias_mes_2 = e.target.value }

            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="meses2ID">
          <Form.Label column sm={2}>
              Mes 2
          </Form.Label>
          <Col sm={3}>
            <Form.Control as="select" defaultValue="Escoja..."
              type="text"
              name="mes_2"
              disabled={ numMeses <= 1 }
              onChange={ (e) => formValues.meses.mes_2 = e.target.value }
              autoComplete="off"
            >
              { meses.map((mes) => <option key={mes} value={mes}>{mes}</option> ) }
            </Form.Control>
          </Col>
        </Form.Group>
        
        <Form.Group as={Row} controlId="dias3ID">
          <Form.Label column sm={2}>
              Día(s) Mes 3
          </Form.Label>
          <Col sm={3}>
            <Form.Control 
              disabled={ numMeses <= 2 }
              type="text"
              name="dias_mes_3"
              placeholder={"17-18-19"}
              autoComplete="off"
              // value={ dia }
              onChange={ (e) => formValues.dias.dias_mes_3 = e.target.value }
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="meses3ID">
          <Form.Label column sm={2}>
              Mes 3
          </Form.Label>
          <Col sm={3}>
            <Form.Control as="select" defaultValue="Escoja..."
              type="text"
              name="mes_3"
              disabled={ numMeses <= 2 }
              onChange={ (e) => formValues.meses.mes_3 = e.target.value }
              autoComplete="off"
            >
              { meses.map((mes) => <option key={mes} value={mes}>{mes}</option> ) }

            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="ano2ID">
          <Form.Label column sm={2}>
              Año
          </Form.Label>
          <Col sm={3}>
            <Form.Control 
              type="number"
              name="ano"
              placeholder={"20XX"}
              autoComplete="off"
              value={ ano }
              onChange={ handleInputChange }
            />
          </Col>
        </Form.Group>

      <h2>Firmas</h2>

      <Form.Group as={Row} controlId="firmas2ID">
          <Form.Label column sm={2}>
              Número de Firmas
          </Form.Label>
          <Col sm={3}>
            <Form.Control as="select" defaultValue="Escoja..."
              type="text"
              name="numFirmas"
              onChange={ handleInputChange }
              autoComplete="off"
            >
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>

            </Form.Control>
          </Col>
        </Form.Group>

      <h4>Firma 1</h4>
        <Form.Group as={Row} controlId="firma1ID2">
          <Form.Label column sm={2}>
              Nombre
          </Form.Label>
          <Col sm={3}>
            <Form.Control 
              disabled={ numFirmas < 1 }
              type="text"
              name="nombreFirma1"
              placeholder={""}
              autoComplete="off"
              onChange={ (e) => formValues.firmas.nombreFirma1 = e.target.value }
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="cargo1ID2">
          <Form.Label column sm={2}>
              Cargo
          </Form.Label>
          <Col sm={3}>
            <Form.Control 
              disabled={ numFirmas < 1 }
              type="text"
              name="cargo1"
              placeholder={""}
              autoComplete="off"
              onChange={ (e) => formValues.firmas.cargo1 = e.target.value }
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="firma1imagenID2">
          <Form.Label column sm={2}>
              Imagen firma
          </Form.Label>
          <Col sm={3}>
            <Form.File 
              disabled={ numFirmas < 1 }
              type="file"
              name="firma1url"
              placeholder={""}
              onChange={ (e) => {
                try {
                  formValues.firmas.firma1url = URL.createObjectURL(e.target.files[0]);
                } catch (error) {
                  console.log(error);
                }
                
              }
               }
            />
          </Col>
        </Form.Group>

        <h4>Firma 2</h4>
          <Form.Group as={Row} controlId="firma2ID2">
            <Form.Label column sm={2}>
                Nombre
            </Form.Label>
            <Col sm={3}>
              <Form.Control 
                disabled={ numFirmas <= 1 }
                type="text"
                name="nombreFirma2"
                placeholder={""}
                autoComplete="off"
                onChange={ (e) => formValues.firmas.nombreFirma2 = e.target.value }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="cargo2ID2">
            <Form.Label column sm={2}>
                Cargo
            </Form.Label>
            <Col sm={3}>
              <Form.Control 
                disabled={ numFirmas <= 1 }
                type="text"
                name="cargo2"
                placeholder={""}
                autoComplete="off"
                onChange={ (e) => formValues.firmas.cargo2 = e.target.value }
              />
            </Col>
          </Form.Group>

        <Form.Group as={Row} controlId="firma2imagenID2">
          <Form.Label column sm={2}>
              Imagen firma
          </Form.Label>
          <Col sm={3}>
            <Form.File 
              disabled={ numFirmas <= 1 }
              type="file"
              name="firma2url"
              placeholder={""}
              onChange={ (e) => {
                try {
                  formValues.firmas.firma2url = URL.createObjectURL(e.target.files[0]);
                } catch (error) {
                  console.log(error);
                }
                
              } }
            />
          </Col>
        </Form.Group>

        <h4>Firma 3</h4>
          <Form.Group as={Row} controlId="firma3ID2">
              <Form.Label column sm={2}>
                  Nombre
              </Form.Label>
              <Col sm={3}>
                <Form.Control 
                  disabled={ numFirmas <= 2 }
                  type="text"
                  name="nombreFirma3"
                  placeholder={""}
                  autoComplete="off"
                  onChange={ (e) => formValues.firmas.nombreFirma3 = e.target.value }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="cargo3ID2">
              <Form.Label column sm={2}>
                  Cargo
              </Form.Label>
              <Col sm={3}>
                <Form.Control
                  disabled={ numFirmas <= 2 } 
                  type="text"
                  name="cargo3"
                  placeholder={""}
                  autoComplete="off"
                  onChange={ (e) => formValues.firmas.cargo3 = e.target.value }
                />
              </Col>
            </Form.Group>

          <Form.Group as={Row} controlId="firma3imagenID2">
            <Form.Label column sm={2}>
                Imagen firma
            </Form.Label>
            <Col sm={3}>
              <Form.File 
                disabled={ numFirmas <= 2 }
                type="file"
                name="firma3url"
                placeholder={""}
                onChange={ (e) => {
                  try {
                    formValues.firmas.firma3url = URL.createObjectURL(e.target.files[0]);
                  } catch (error) {
                    console.log(error);
                  }
                  
                } }
              />
            </Col>
          </Form.Group>

          <h4>Firma 4</h4>
          <Form.Group as={Row} controlId="firma4ID2">
              <Form.Label column sm={2}>
                  Nombre
              </Form.Label>
              <Col sm={3}>
                <Form.Control 
                  disabled={ numFirmas <= 3 }
                  type="text"
                  name="nombreFirma4"
                  placeholder={""}
                  autoComplete="off"
                  onChange={ (e) => formValues.firmas.nombreFirma4 = e.target.value }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="cargo4ID2">
              <Form.Label column sm={2}>
                  Cargo
              </Form.Label>
              <Col sm={3}>
                <Form.Control
                  disabled={ numFirmas <= 3 } 
                  type="text"
                  name="cargo4"
                  placeholder={""}
                  autoComplete="off"
                  onChange={ (e) => formValues.firmas.cargo4 = e.target.value }
                />
              </Col>
            </Form.Group>

          <Form.Group as={Row} controlId="firma4imagenID2">
            <Form.Label column sm={2}>
                Imagen firma
            </Form.Label>
            <Col sm={3}>
              <Form.File 
                disabled={ numFirmas <= 3 }
                type="file"
                name="firma4url"
                placeholder={""}
                onChange={ (e) => {
                  try {
                    formValues.firmas.firma4url = URL.createObjectURL(e.target.files[0]);
                  } catch (error) {
                    console.log(error);
                  }
                  
                } }
              />
            </Col>
          </Form.Group>

          <h4>Firma 5</h4>
          <Form.Group as={Row} controlId="firma5ID2">
              <Form.Label column sm={2}>
                  Nombre
              </Form.Label>
              <Col sm={3}>
                <Form.Control 
                  disabled={ numFirmas <= 4 }
                  type="text"
                  name="nombreFirma5"
                  placeholder={""}
                  autoComplete="off"
                  onChange={ (e) => formValues.firmas.nombreFirma5 = e.target.value }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="cargo5ID2">
              <Form.Label column sm={2}>
                  Cargo
              </Form.Label>
              <Col sm={3}>
                <Form.Control
                  disabled={ numFirmas <= 4 } 
                  type="text"
                  name="cargo5"
                  placeholder={""}
                  autoComplete="off"
                  onChange={ (e) => formValues.firmas.cargo5 = e.target.value }
                />
              </Col>
            </Form.Group>

          <Form.Group as={Row} controlId="firma5imagenID2">
            <Form.Label column sm={2}>
                Imagen firma
            </Form.Label>
            <Col sm={3}>
              <Form.File 
                disabled={ numFirmas <= 4 }
                type="file"
                name="firma5url"
                placeholder={""}
                onChange={ (e) => {
                  try {
                    formValues.firmas.firma5url = URL.createObjectURL(e.target.files[0]);
                  } catch (error) {
                    console.log(error);
                  }
                  
                } }
              />
            </Col>
          </Form.Group>


      </Form>

      <div>
        <Button style={{
              marginLeft: 0,
              marginRight: 30,
              width: '300px',
              color: 'white',
              fontSize: '1.2em',
              marginTop: '30px',
              fontWeight: '700',
              backgroundColor: '#00C6CF',
              padding:'15px',
              borderRadius: '15px',
              marginBottom: '5px',
              }} onClick={ CrearCertificados } >Crear Certificados</Button> <br />
        <Button style={{
              marginLeft: 0,
              marginRight: 30,
              width: '300px',
              color: 'white',
              fontSize: '1.2em',
              fontWeight: '700',
              backgroundColor: '#00B1E0',
              padding:'15px',
              borderRadius: '15px',
              marginBottom: '5px',
              }}onClick={ descargarCertificados } >Descargar Certificados</Button> <br />
        <Button style={{
              marginLeft: 0,
              marginRight: 30,
              width: '300px',
              color: 'white',
              fontWeight: '700',
              fontSize: '1.2em',
              backgroundColor: '#2D96E1',
              padding:'15px',
              borderRadius: '15px',
              marginBottom: '5px',
              }}onClick={ guardarBD } >Guardar en la Base de Datos</Button> <br />
      </div>
    
    </Content>
    <Footer style={{ marginTop:'auto', marginBottom:'0px', textAlign: 'center'}}>Iniciativa impulsada por la Directiva de ANACEM 2019-2020 para todos sus miembros ❤ 
        <br>
        </br>
        <a href="mailto:presidente@anacem.cl"> Contacto ANACEM </a>
        <br>
        </br>
        <a href="mailto:luisbastian.blanco@gmail.com"> Equipo de Desarrolladores </a></Footer>
      </>

  )



}


