import React from 'react';
import ReactDOM from 'react-dom';
import { CertificadoApp } from './CertificadoApp';

import './styles/styles.scss'


ReactDOM.render(
    <CertificadoApp />,
  document.getElementById('root')
);